/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import LogoIcon from '../Root/LogoIcon';
import {
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
  HomePageToolkit,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import Grid from '@material-ui/core/Grid';
import React from 'react';

const clockConfigs: ClockConfig[] = [
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'KRK',
    timeZone: 'Europe/Warsaw',
  },
  {
    label: 'OSL',
    timeZone: 'Europe/Oslo',
  },
  {
    label: 'STO',
    timeZone: 'Europe/Stockholm',
  },
];

export const HomePage = () => {
  return (
    <Page themeId="home">
      <Header title={<WelcomeTitle />} pageTitleOverride="Home">
        <HeaderWorldClock clockConfigs={clockConfigs} />
      </Header>
      <Content>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <HomePageSearchBar />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <HomePageStarredEntities />
            </Grid>
            <Grid item xs={12} md={6}>
              <HomePageToolkit
                title="Links"
                tools={[
                  {
                    url: 'https://docs.schibsted.io',
                    label: 'P&T docs',
                    icon: <LogoIcon />,
                  },
                  {
                    url: 'https://docs.schibsted.media',
                    label: 'SPP Documentation',
                    icon: <LogoIcon />,
                  },
                  {
                    url: '/catalog-extensions?filters%5Bkind%5D=component&filters%5Buser%5D=all&columns%5B0%5D%5Bfield%5D=resolved.name&columns%5B0%5D%5Btitle%5D=Name&columns%5B1%5D%5Bfield%5D=resolved.ownedByRelationsTitle&columns%5B1%5D%5Btitle%5D=Owner&columns%5B2%5D%5Bfield%5D=entity.spec.type&columns%5B2%5D%5Btitle%5D=Type&columns%5B3%5D%5Bfield%5D=entity.metadata.schibsted-criticality&columns%5B3%5D%5Btitle%5D=Criticality&columns%5B4%5D%5Bfield%5D=entity.metadata.schibsted-harm-assessment&columns%5B4%5D%5Btitle%5D=HARM%20assessment&columns%5B5%5D%5Bfield%5D=entity.metadata.schibsted-rto&columns%5B5%5D%5Btitle%5D=RTO&columns%5B6%5D%5Bfield%5D=entity.metadata.schibsted-rpo&columns%5B6%5D%5Btitle%5D=RPO',
                    label: 'Security Classifications',
                    icon: <LogoIcon />,
                  },
                  {
                    url: 'https://metrics.schibsted.io/',
                    label: 'SRE Metrics Platform',
                    icon: <img height="28" src="/grafana.svg" />,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
