/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

type Classes = {
  svg: string;
  path: string;
};

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    paddingLeft: 3,
  },
  path: {
    fill: '#fdfdfd',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
});

const LogoFull = ({ classes = useStyles() }: { classes?: Classes }) => {
  return (
    <svg
      className={classes.svg}
      viewBox="0 0 100 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.path}
        d="M10.9201 9.66458C10.8516 9.52613 10.7563 9.3259 10.6076 9.13835C10.5108 9.01621 10.3747 8.93262 10.2105 8.93262C9.95324 8.93262 9.77759 9.11926 9.77759 9.37936C9.77759 9.46857 9.80761 9.54933 9.84603 9.62453C9.86705 9.66578 9.90667 9.74678 9.92769 9.78883C9.94128 9.81607 9.94074 9.84331 9.91736 9.85593C9.88988 9.87146 9.8639 9.85787 9.84947 9.83395C9.82712 9.79692 9.75778 9.68626 9.75778 9.68626C9.67406 9.55573 9.53242 9.46029 9.36576 9.46029C9.10235 9.46029 8.89746 9.67853 8.89746 9.93971C8.89746 10.0146 8.91595 10.085 8.94663 10.1481C8.95895 10.1735 8.98269 10.2246 8.99507 10.2498C9.0086 10.2773 9.00891 10.305 8.98517 10.3195C8.96058 10.3344 8.93376 10.3201 8.91921 10.2977C8.90127 10.2701 8.86478 10.2153 8.8457 10.1886C8.76675 10.0784 8.64045 10.0118 8.4988 10.0118C8.26426 10.0118 8.07031 10.1881 8.07031 10.4572C8.07031 10.5416 8.10649 10.6465 8.14485 10.7315C8.33511 11.1535 8.42807 11.5648 8.42807 12.1976C8.42807 13.6899 7.26103 14.8996 5.85039 14.8996C3.98183 14.8996 3.28678 13.462 3.15353 13.0344C3.13758 12.9831 3.08292 12.9546 3.03158 12.9704L0.252569 13.8257C0.200925 13.8416 0.171026 13.8963 0.187032 13.948C0.800663 15.9262 2.75777 18.0001 5.8191 18.0001C8.35583 18.0001 10.4639 16.3006 11.2372 14.0067C11.7198 12.5748 11.5715 10.9822 10.9201 9.66458Z"
      />
      <path
        className={classes.path}
        d="M5.76103 9.27615C5.61147 9.27615 5.49127 9.15492 5.49127 9.00657C5.49127 8.85702 5.6125 8.73682 5.76079 8.73682C5.91035 8.73682 6.03061 8.85804 6.03061 9.00639C6.03061 9.15595 5.90932 9.27615 5.76103 9.27615Z"
      />
      <path
        className={classes.path}
        d="M5.76103 6.96487C5.61147 6.96487 5.49127 6.84358 5.49127 6.6953C5.49127 6.54574 5.6125 6.42554 5.76079 6.42554C5.91035 6.42554 6.03061 6.54677 6.03061 6.69511C6.03061 6.84461 5.90932 6.96487 5.76103 6.96487Z"
      />
      <path
        className={classes.path}
        d="M5.76103 8.12039C5.61147 8.12039 5.49127 7.99916 5.49127 7.85081C5.49127 7.70126 5.6125 7.58105 5.76079 7.58105C5.91035 7.58105 6.03061 7.70234 6.03061 7.85063C6.03061 8.00019 5.90932 8.12039 5.76103 8.12039Z"
      />
      <path
        className={classes.path}
        d="M7.88592 9.42411C7.82872 9.36461 7.75092 9.30076 7.68303 9.25377C7.52454 9.14402 7.3786 9.05323 7.20374 8.97272C7.09187 8.92119 7.0392 8.89431 6.9013 8.85535C6.79862 8.82636 6.70094 8.8135 6.60829 8.85197C6.4635 8.91201 6.38347 9.06574 6.4301 9.23281C6.4658 9.36056 6.56915 9.41148 6.67974 9.46458C6.85709 9.54974 6.94509 9.59511 7.0958 9.68807C7.21328 9.76049 7.31337 9.82723 7.43272 9.91246C7.57431 10.0136 7.75352 10.0301 7.88 9.9182C8.02298 9.7916 8.02527 9.56913 7.88592 9.42411Z"
      />
      <path
        className={classes.path}
        d="M8.85036 8.74939C8.66916 8.56419 8.46421 8.40461 8.26621 8.30295C8.20031 8.26918 8.15126 8.24484 8.06471 8.22455C7.93702 8.19459 7.80353 8.23367 7.72017 8.33466C7.62975 8.44423 7.62051 8.59421 7.69239 8.71683C7.75255 8.81951 7.83173 8.87067 7.92488 8.93826C8.06459 9.0398 8.16153 9.11675 8.29206 9.22687C8.37693 9.29851 8.44899 9.37594 8.56569 9.39189C8.71687 9.41261 8.86824 9.33318 8.93722 9.19709C9.01194 9.04959 8.96598 8.86753 8.85036 8.74939Z"
      />
      <path
        className={classes.path}
        d="M7.16089 7.76614C7.07941 7.73129 7.01206 7.70344 6.89554 7.6736C6.80935 7.65156 6.70636 7.63893 6.61727 7.65983C6.41516 7.70725 6.31936 7.95043 6.43509 8.12312C6.48196 8.19313 6.54345 8.22357 6.62868 8.25377C6.69724 8.27805 6.74798 8.29642 6.82982 8.32638C6.89041 8.3486 6.96029 8.37627 7.01568 8.3869C7.11553 8.40599 7.22063 8.3744 7.29372 8.30378C7.4655 8.13792 7.40782 7.87191 7.16089 7.76614Z"
      />
      <path
        className={classes.path}
        d="M6.88137 6.53665C6.8368 6.52427 6.7802 6.51201 6.72517 6.50748C6.65541 6.5018 6.57664 6.51122 6.5134 6.55393C6.38957 6.63759 6.34765 6.80665 6.41911 6.93821C6.45378 7.00206 6.51213 7.05195 6.58075 7.07587C6.64339 7.09773 6.71593 7.12099 6.78207 7.12854C6.92903 7.14521 7.06862 7.04899 7.10565 6.90602C7.14859 6.74021 7.04645 6.58256 6.88137 6.53665Z"
      />
      <path
        className={classes.path}
        d="M8.32337 7.02088C8.24491 6.97201 8.17545 6.93517 8.08871 6.90243C7.99369 6.86655 7.90188 6.83985 7.81067 6.83375C7.59594 6.81932 7.41999 7.01738 7.45835 7.22891C7.47701 7.33189 7.54514 7.42201 7.63865 7.46883C7.71246 7.50585 7.76882 7.534 7.84861 7.57447C7.92641 7.61397 8.00753 7.66242 8.09221 7.68513C8.19538 7.71279 8.30755 7.68996 8.39187 7.62454C8.59259 7.46883 8.55061 7.16234 8.32337 7.02088Z"
      />
      <path
        className={classes.path}
        d="M9.85435 8.14555C9.77323 8.04607 9.69265 7.9638 9.60609 7.88794C9.5138 7.807 9.4508 7.75578 9.34105 7.68782C9.24627 7.62911 9.13652 7.59788 9.02291 7.61933C8.90693 7.64113 8.80793 7.72382 8.76529 7.83382C8.71775 7.95631 8.7456 8.09868 8.83554 8.19442C8.90174 8.26491 8.96208 8.31154 9.03481 8.37575C9.12613 8.45633 9.21348 8.53563 9.29883 8.62255C9.37572 8.70096 9.4595 8.76118 9.5745 8.76541C9.71192 8.77048 9.84269 8.6931 9.90454 8.57031C9.97274 8.43488 9.95951 8.27482 9.85435 8.14555Z"
      />
      <path
        className={classes.path}
        d="M0.601063 8.34912C0.66956 8.48763 0.764875 8.68786 0.913526 8.87541C1.01029 8.99754 1.14644 9.0812 1.31061 9.0812C1.56793 9.0812 1.74358 8.8945 1.74358 8.63446C1.74358 8.54519 1.7135 8.46449 1.67514 8.38917C1.65412 8.34803 1.6145 8.26703 1.59348 8.22493C1.57983 8.19769 1.58043 8.17039 1.60381 8.15777C1.63129 8.1423 1.65726 8.15595 1.6717 8.17981C1.69405 8.21684 1.76339 8.3275 1.76339 8.3275C1.84705 8.45809 1.98875 8.55346 2.15535 8.55346C2.41882 8.55346 2.62365 8.33523 2.62365 8.07405C2.62365 7.99909 2.60522 7.92872 2.57454 7.8656C2.56222 7.84029 2.53848 7.78925 2.5261 7.76394C2.51251 7.73646 2.51226 7.70879 2.536 7.6943C2.56053 7.67932 2.58741 7.69363 2.60196 7.71604C2.6199 7.74365 2.65632 7.79849 2.67547 7.82519C2.75436 7.93542 2.88072 8.00193 3.02237 8.00193C3.25691 8.00193 3.45086 7.82555 3.45086 7.55646C3.45086 7.47226 3.41462 7.36728 3.37632 7.28229C3.186 6.86026 3.09304 6.44897 3.09304 5.81613C3.09304 4.32383 4.26014 3.1142 5.67078 3.1142C7.53934 3.1142 8.23439 4.55185 8.36758 4.97938C8.38359 5.03066 8.43825 5.05917 8.48953 5.04334L11.2685 4.1881C11.3202 4.17222 11.3501 4.11749 11.3341 4.06585C10.7205 2.0876 8.7634 0.0136719 5.70207 0.0136719C3.16534 0.0136719 1.05722 1.71316 0.28401 4.00701C-0.198728 5.43898 -0.0503795 7.03156 0.601063 8.34912Z"
      />
      <path
        className={classes.path}
        d="M5.76085 11.0482C5.91035 11.0482 6.03061 11.1694 6.03061 11.3178C6.03061 11.4673 5.90932 11.5875 5.76103 11.5875C5.61147 11.5875 5.49127 11.4663 5.49127 11.3179C5.49127 11.1684 5.6125 11.0482 5.76085 11.0482Z"
      />
      <path
        className={classes.path}
        d="M5.76085 9.89246C5.91035 9.89246 6.03061 10.0137 6.03061 10.162C6.03061 10.3116 5.90932 10.4318 5.76103 10.4318C5.61147 10.4318 5.49127 10.3106 5.49127 10.1623C5.49127 10.0127 5.6125 9.89246 5.76085 9.89246Z"
      />
      <path
        className={classes.path}
        d="M3.63605 8.58889C3.69325 8.64845 3.77105 8.71223 3.83888 8.75922C3.99743 8.86904 4.14331 8.95976 4.31817 9.04022C4.4301 9.0918 4.48277 9.11868 4.62067 9.15764C4.72329 9.18663 4.82096 9.1995 4.91368 9.16102C5.05847 9.10098 5.1385 8.94726 5.09181 8.78024C5.05617 8.65249 4.95282 8.60151 4.84223 8.54842C4.66488 8.46325 4.57688 8.41789 4.42611 8.32493C4.30869 8.25245 4.2086 8.18576 4.08925 8.10053C3.9476 7.99948 3.76845 7.98287 3.64196 8.0948C3.49899 8.2214 3.4967 8.4438 3.63605 8.58889Z"
      />
      <path
        className={classes.path}
        d="M2.67113 9.26361C2.85234 9.44874 3.05729 9.60833 3.25529 9.70993C3.32118 9.74375 3.37023 9.76809 3.45673 9.78839C3.58448 9.81835 3.71797 9.77927 3.80127 9.67828C3.89175 9.5687 3.90093 9.41879 3.82905 9.29611C3.76895 9.19342 3.6897 9.14232 3.59656 9.07467C3.45685 8.97319 3.35996 8.89624 3.22943 8.78607C3.14451 8.71443 3.07251 8.63699 2.95575 8.62105C2.80462 8.60039 2.65325 8.67976 2.58427 8.81585C2.50949 8.96341 2.55546 9.14546 2.67113 9.26361Z"
      />
      <path
        className={classes.path}
        d="M4.36046 10.2468C4.44195 10.2817 4.5093 10.3095 4.62575 10.3393C4.71201 10.3614 4.81493 10.3741 4.90403 10.3531C5.10614 10.3057 5.20194 10.0625 5.08626 9.8899C5.03933 9.81983 4.9779 9.78939 4.89267 9.75919C4.82412 9.73485 4.77338 9.71661 4.69147 9.68659C4.63095 9.66436 4.56106 9.63669 4.50567 9.62612C4.40577 9.60692 4.30067 9.63863 4.22764 9.70918C4.05585 9.8751 4.11354 10.1411 4.36046 10.2468Z"
      />
      <path
        className={classes.path}
        d="M4.63993 11.4765C4.6845 11.4888 4.7411 11.5011 4.79619 11.5056C4.86589 11.5113 4.94472 11.5018 5.00796 11.4591C5.13179 11.3755 5.17371 11.2064 5.10225 11.0748C5.06758 11.0109 5.00923 10.9612 4.94061 10.9372C4.87797 10.9153 4.80543 10.8921 4.73929 10.8846C4.59233 10.8678 4.45268 10.9641 4.41565 11.107C4.37277 11.2728 4.47485 11.4305 4.63993 11.4765Z"
      />
      <path
        className={classes.path}
        d="M3.19876 10.9921C3.27722 11.0409 3.34668 11.0778 3.43348 11.1106C3.5285 11.1464 3.62031 11.1731 3.71145 11.1793C3.92625 11.1936 4.1022 10.9956 4.06378 10.784C4.04512 10.6811 3.97705 10.5909 3.88354 10.5441C3.80973 10.5072 3.75331 10.4789 3.67352 10.4385C3.59572 10.399 3.5146 10.3505 3.42998 10.3278C3.32681 10.3002 3.21464 10.323 3.13032 10.3884C2.9296 10.5441 2.97152 10.8507 3.19876 10.9921Z"
      />
      <path
        className={classes.path}
        d="M1.66725 9.8674C1.74831 9.96694 1.82895 10.0492 1.91551 10.125C2.0078 10.206 2.0708 10.2572 2.1805 10.3252C2.27527 10.3839 2.38502 10.4151 2.49864 10.3937C2.61461 10.3718 2.71367 10.2891 2.75631 10.1792C2.80385 10.0567 2.77594 9.91427 2.68601 9.81854C2.61986 9.74805 2.55952 9.70147 2.4868 9.63721C2.39547 9.55663 2.30813 9.47732 2.22278 9.3904C2.14588 9.312 2.06211 9.25178 1.94704 9.24755C1.80968 9.24248 1.67885 9.31985 1.61706 9.44271C1.5488 9.57813 1.56203 9.73814 1.66725 9.8674Z"
      />
      <path
        className={classes.path}
        d="M31.9134 6.18761C30.8473 6.18761 29.6226 6.55045 28.8968 7.41234V1.3171C28.8968 1.26365 28.8535 1.22034 28.8001 1.22034H25.977C25.9235 1.22034 25.8802 1.26365 25.8802 1.3171V17.545C25.8802 17.5985 25.9235 17.6418 25.977 17.6418H28.8001C28.8535 17.6418 28.8968 17.5985 28.8968 17.545V11.0868C28.8852 9.84131 29.6042 8.862 30.9835 8.86279C32.4351 8.86357 33.0476 9.88462 33.0476 11.1321V17.545C33.0476 17.5985 33.0909 17.6418 33.1443 17.6418H35.9674C36.0208 17.6418 36.0642 17.5985 36.0642 17.545V10.6105C36.0642 8.16091 34.7486 6.18761 31.9134 6.18761Z"
      />
      <path
        className={classes.path}
        d="M40.1726 0.948176C39.1454 0.940203 38.2983 1.78234 38.2983 2.83057C38.2983 3.82866 39.1375 4.66783 40.1582 4.66783C41.283 4.66783 42.1441 3.69257 42.0029 2.59439C41.8846 1.67458 41.0999 0.955304 40.1726 0.948176Z"
      />
      <path
        className={classes.path}
        d="M41.5811 6.4823H38.758C38.7046 6.4823 38.6612 6.52561 38.6612 6.57906V17.5449C38.6612 17.5984 38.7046 17.6417 38.758 17.6417H41.5811C41.6345 17.6417 41.6778 17.5984 41.6778 17.5449V6.57906C41.6778 6.52561 41.6345 6.4823 41.5811 6.4823Z"
      />
      <path
        className={classes.path}
        d="M49.9915 15.2383C48.4718 15.2383 47.1771 14.0581 47.1771 12.0395C47.1771 9.99809 48.4718 8.86348 49.9915 8.86348C51.5339 8.86348 52.7586 9.99809 52.7586 12.0395C52.7586 14.0808 51.5339 15.2383 49.9915 15.2383ZM50.6266 6.18761C48.9708 6.18761 47.7459 6.91341 47.247 7.70722V1.3171C47.247 1.26365 47.2036 1.22034 47.1501 1.22034H44.3725C44.3191 1.22034 44.2757 1.26365 44.2757 1.3171V17.5449C44.2757 17.5985 44.3191 17.6418 44.3725 17.6418H47.1048C47.1583 17.6418 47.2016 17.5985 47.2016 17.5449V16.2809C47.7913 17.2335 48.9708 17.914 50.5132 17.914C53.6885 17.914 55.7752 15.3962 55.7752 12.0168C55.7752 8.70526 53.8926 6.18761 50.6266 6.18761Z"
      />
      <path
        className={classes.path}
        d="M73.8408 15.0586C73.6438 15.0942 73.3189 15.1241 73.0241 15.1241C72.1622 15.1241 71.7085 14.8066 71.7085 13.8312V9.1588H73.8571C73.9108 9.1588 73.9543 9.1155 73.9543 9.06204V6.57913C73.9543 6.52567 73.9108 6.48236 73.8571 6.48236H71.7085V3.24496C71.7085 3.1915 71.665 3.14819 71.6119 3.14819H68.8225C68.7687 3.14819 68.7258 3.19138 68.7252 3.24484L68.721 6.48236H66.793C66.7392 6.48236 66.6957 6.52567 66.6957 6.57913V9.06204C66.6957 9.1155 66.7392 9.1588 66.793 9.1588H68.715V14.3529C68.715 16.5076 70.0758 17.8006 72.2528 17.8006C73.073 17.8006 73.601 17.664 73.8903 17.5538C73.9289 17.5391 73.9543 17.5023 73.9543 17.461V15.1546C73.9543 15.0943 73.8999 15.0478 73.8408 15.0586Z"
      />
      <path
        className={classes.path}
        d="M93.4353 15.2382C91.8479 15.2382 90.6682 14.0354 90.6682 12.0394C90.6682 10.0435 91.8932 8.88611 93.4353 8.88611C94.9773 8.88611 96.215 10.0207 96.215 12.0168C96.215 14.0354 94.9773 15.2382 93.4353 15.2382ZM99.0393 1.22034H96.2312C96.1774 1.22034 96.1345 1.26365 96.1345 1.3171V7.59379C95.8168 7.00408 94.9096 6.21026 92.9815 6.21026C89.8291 6.21026 87.6286 8.77321 87.6286 12.0394C87.6286 15.419 89.8968 17.914 93.0721 17.914C94.5695 17.914 95.7033 17.2335 96.2022 16.349L96.2082 17.5454C96.2082 17.5988 96.2517 17.6418 96.3049 17.6418H99.0393C99.0925 17.6418 99.1359 17.5985 99.1359 17.5449V1.3171C99.1359 1.26365 99.0925 1.22034 99.0393 1.22034Z"
      />
      <path
        className={classes.path}
        d="M78.2565 10.7918C78.3241 9.77116 79.1867 8.59174 80.7517 8.59174C82.475 8.59174 83.201 9.68037 83.2463 10.7918H78.2565ZM80.7055 6.14209C77.8479 6.14209 75.2167 8.45563 75.2167 12.0167C75.2167 15.7818 77.9161 17.9819 80.9779 17.9819C83.263 17.9819 84.8715 16.8652 85.6737 15.3785C85.7002 15.3288 85.6779 15.2665 85.6259 15.244L83.3645 14.2612C83.321 14.2423 83.2708 14.2585 83.2449 14.2984C82.8136 14.9648 82.102 15.3962 81.0009 15.3962C79.5035 15.3962 78.2562 14.3302 78.1879 12.8559H86.0814C86.1321 12.8559 86.1744 12.8176 86.178 12.7671C86.1901 12.606 86.2173 12.2592 86.2173 11.926C86.2173 8.3422 84.1533 6.14209 80.7055 6.14209Z"
      />
      <path
        className={classes.path}
        d="M62.6815 10.8507L61.0935 10.5105C60.4811 10.397 60.0729 10.0887 60.0729 9.54439C60.0729 8.90932 60.7082 8.43298 61.5019 8.43298C62.5903 8.43298 63.0947 9.04879 63.2741 9.63904C63.2898 9.69202 63.349 9.71902 63.4009 9.69902L65.6479 8.82832C65.6962 8.80971 65.7222 8.75656 65.7053 8.70775C65.3066 7.54458 64.1264 6.14209 61.4789 6.14209C59.0748 6.14209 57.3057 7.79791 57.3057 9.79385C57.3057 11.3589 58.2811 12.6952 60.4358 13.1715L61.9102 13.5118C62.7721 13.6933 63.1122 14.058 63.1122 14.557C63.1122 15.1467 62.6362 15.6684 61.6378 15.6684C60.3737 15.6684 59.717 14.9144 59.6072 14.0707C59.5999 14.0142 59.5466 13.9783 59.491 13.9905L57.0971 14.5154C57.0483 14.5262 57.0147 14.5723 57.0216 14.6219C57.2004 15.9018 58.3797 17.9819 61.6607 17.9819C64.5866 17.9819 65.9928 16.1221 65.9928 14.3076C65.9928 12.6744 64.8814 11.3043 62.6815 10.8507Z"
      />
      <path
        className={classes.path}
        d="M24.1025 14.1874L21.6322 13.364C21.5797 13.3464 21.5245 13.3753 21.5071 13.4279C21.2295 14.2678 20.4594 15.2148 18.9738 15.2148C17.4314 15.2148 16.116 14.0808 16.116 12.062C16.116 10.0434 17.4087 8.93197 18.9511 8.93197C20.2354 8.93197 20.9342 9.63324 21.2789 10.4197C21.2994 10.4663 21.3512 10.49 21.3993 10.4734L23.9302 9.6002C23.981 9.58269 24.0086 9.52706 23.9905 9.4765C23.3743 7.74796 21.6901 6.14209 18.883 6.14209C15.6396 6.14209 13.0992 8.61443 13.0992 12.062C13.0992 15.4869 15.6849 17.9819 18.9738 17.9819C21.8976 17.9819 23.6355 16.1371 24.1656 14.3059C24.1801 14.2562 24.1517 14.2039 24.1025 14.1874Z"
      />
    </svg>
  );
};

export default LogoFull;
