/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AboutField } from './AboutField';
import { Grid } from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';

const ignore = [
  'description',
  'etag',
  'generation',
  'name',
  'namespace',
  'uid',
];

export function EntityMetadataCard() {
  const { entity } = useEntity();
  const metadata = Object.keys(entity.metadata)
    .filter(
      key =>
        !ignore.includes(key) &&
        entity.metadata[key] !== 'unknown' &&
        typeof entity.metadata[key] !== 'object',
    )
    .map(key => {
      return (
        <AboutField
          key={key}
          label={key}
          value={entity.metadata[key] as string}
          gridSizes={{ xs: 12 }}
        />
      );
    });
  return (
    <InfoCard title="Metadata">
      <Grid container>{metadata}</Grid>
    </InfoCard>
  );
}
