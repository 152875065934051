/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EntityTable } from '@backstage/plugin-catalog-react';
import { FeatureEntityV1alpha1 as FeatureEntity } from '@schibsted/backstage-plugin-feature-common';
import { TableColumn } from '@backstage/core-components';

// TODO: This could be moved to plugin-catalog-react if we wouldn't have a
// special createSpecFeatureTypeColumn. But this is required to use FeatureTypeTitle to
// resolve the display name of an entity. Is the display name really worth it?

export const featureEntityColumns: TableColumn<FeatureEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'Feature' }),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];
