import { rootRoute } from './routes';

import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const featurePlugin = createPlugin({
  id: 'feature',
  routes: {
    root: rootRoute,
  },
});

export const FeatureExplorerPage = featurePlugin.provide(
  createRoutableExtension({
    name: 'FeatureExplorerPage',
    component: () =>
      import('./components/FeatureExplorerPage').then(
        m => m.FeatureExplorerPage,
      ),
    mountPoint: rootRoute,
  }),
);

export const EntityImplementsFeaturesCard = featurePlugin.provide(
  createComponentExtension({
    name: 'EntityImplementsFeaturesCard',
    component: {
      lazy: () =>
        import('./components/FeaturesCards').then(
          m => m.ImplementsFeaturesCard,
        ),
    },
  }),
);

export const EntityImplementingComponentsCard = featurePlugin.provide(
  createComponentExtension({
    name: 'EntityImplementingComponentsCard',
    component: {
      lazy: () =>
        import('./components/ComponentsCards').then(
          m => m.ImplementingComponentsCard,
        ),
    },
  }),
);
