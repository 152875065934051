import {
  MovedState,
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import { ringIds } from '.';

const colors = ['#30588e', '#186ef0', '#458fff', '#ff716b'];

// only has content in the first cell
const isQuadrantTitleRow = (row: string[]) => row[0] !== '' && row[1] === '';

const splitRow = (row: string) =>
  row.split('","').map(v => v.replace(/"/g, ''));

const addQuadrant = (quadrants: RadarQuadrant[], row: string[]): string => {
  const [title] = row;
  quadrants.push({ id: title, name: title });
  return title;
};

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

/**
 *
 * @param sheetsData
 * @returns
 */
export const transform = (sheetsData: string): TechRadarLoaderResponse => {
  const entries: RadarEntry[] = [];
  const quadrants: RadarQuadrant[] = [];

  const rings: Record<string, RadarRing> = ringIds.reduce(
    (acc, ringId, index) => {
      acc[ringId] = {
        id: ringId,
        name: capitalize(ringId),
        color: colors[index],
      };
      return acc;
    },
    {} as Record<string, RadarRing>,
  );

  const addEntry = (
    entries: RadarEntry[],
    row: string[],
    quadrant: string,
    date: Date,
  ) => {
    const [, title, ring, description, statusComment, url] = row;
    if (title && rings[ring]) {
      entries.push({
        id: title,
        quadrant,
        key: title,
        title,
        description,
        url,
        timeline: [{ ringId: ring, date, description: statusComment }],
      });
    } else {
      console.warn(
        'Unexpected format of row. Expected 6 columns, the first one being empty, Expected title as second element and valid ring as third.',
        row,
      );
    }
  };

  if (sheetsData) {
    const allRows = sheetsData.split('\n').map(splitRow);
    const [dateHeader, _headers, ...rows] = allRows;
    const date = new Date(dateHeader[1]);

    let currentQuadrant: string = '';
    rows.forEach(row => {
      if (isQuadrantTitleRow(row)) {
        currentQuadrant = addQuadrant(quadrants, row);
      } else if (currentQuadrant !== '') {
        addEntry(entries, row, currentQuadrant, date);
      } else {
        console.warn('Ignoring row with invalid format.', row);
      }
    });
  }
  return {
    entries,
    quadrants,
    rings: Object.values(rings),
  };
};

export const addMoveState =
  (previous: TechRadarLoaderResponse) => (entry: RadarEntry) => {
    const previousAppearance = previous?.entries?.find(e => e.id === entry.id);
    if (previousAppearance) {
      const previousRing = previousAppearance.timeline[0].ringId;
      const previousRingIndex = ringIds.findIndex(
        ringId => ringId === previousRing,
      );
      const currentRingIndex = ringIds.findIndex(
        ringId => ringId === entry.timeline[0].ringId,
      );
      entry.timeline[0].moved =
        currentRingIndex === previousRingIndex
          ? MovedState.NoChange
          : currentRingIndex < previousRingIndex
          ? MovedState.Up
          : MovedState.Down;
      entry.timeline.push(...previousAppearance.timeline);
    }
  };
