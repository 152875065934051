/*
Naming rules for relations in priority order:

1. Use at most two words. One main verb and a specifier, e.g. "ownerOf"
2. Reading out "<source-kind> <type> <target-kind>" should make sense in English.
3. Maintain symmetry between pairs, e.g. "ownedBy" and "ownerOf" rather than "owns".
*/

/**
 * A relation denoting a dependency on another entity.
 */
export const RELATION_IMPLEMENTED_BY = 'schibsted-implementedBy';
export const RELATION_IMPLEMENTOR_OF = 'schibsted-implementorOf';
