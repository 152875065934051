/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

type Classes = {
  svg: string;
  path: string;
};

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    paddingLeft: 3,
  },
  path: {
    fill: '#fdfdfd',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
});

const LogoIcon = ({ classes = useStyles() }: { classes?: Classes }) => {
  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="35.83 11 81.99 128.01"
    >
      <path d="M0 0h150v150H0z" fill="none"></path>
      <path
        className={classes.path}
        d="M113.55 79.68a20.21 20.21 0 0 0-2.23-3.74 3.59 3.59 0 0 0-2.82-1.47 3 3 0 0 0-3.09 3.18 3.89 3.89 0 0 0 .49 1.75c.15.29.43.87.58 1.17s.09.39-.07.48a.34.34 0 0 1-.48-.16l-.66-1a3.33 3.33 0 0 0-5.77 3.29c.09.18.26.54.34.72s.1.39-.07.5a.36.36 0 0 1-.47-.16c-.12-.2-.38-.59-.52-.78a3.06 3.06 0 0 0-5.52 1.92 5.32 5.32 0 0 0 .53 2 23.36 23.36 0 0 1 2 10.43c0 10.63-8.31 19.24-18.35 19.24a19.71 19.71 0 0 1-19.2-13.28.69.69 0 0 0-.86-.46l-19.76 5.99a.7.7 0 0 0-.47.87A41.44 41.44 0 0 0 77.24 139c18.05 0 33.06-12.09 38.56-28.42a41 41 0 0 0-2.25-30.91"
      ></path>
      <path
        className={classes.path}
        d="M76.82 76.92A1.92 1.92 0 1 1 78.74 75a1.92 1.92 0 0 1-1.92 1.92"
      ></path>
      <path
        className={classes.path}
        d="M76.82 60.47a1.92 1.92 0 1 1 1.92-1.92 1.93 1.93 0 0 1-1.92 1.92"
      ></path>
      <path
        className={classes.path}
        d="M76.82 68.69a1.92 1.92 0 1 1 1.92-1.92 1.92 1.92 0 0 1-1.92 1.92"
      ></path>
      <path
        className={classes.path}
        d="M92 78a18.59 18.59 0 0 0-7-4 2.33 2.33 0 1 0-1.58 4.34 32 32 0 0 1 5.36 3.19A2.37 2.37 0 0 0 92 78"
      ></path>
      <path
        className={classes.path}
        d="M98.81 73.17a13.57 13.57 0 0 0-5.59-3.74 2.39 2.39 0 0 0-2.65 3.51c1.12 1.57 2.91 2.29 4.27 3.63a3.82 3.82 0 0 0 2 1.17c2.53.39 3.87-2.89 2-4.57"
      ></path>
      <path
        className={classes.path}
        d="M86.79 66.17a7 7 0 0 0-3.87-.76 2.18 2.18 0 0 0-1.3 3.3c.94 1.13 2.79 1.36 4.14 1.88a2.27 2.27 0 0 0 1-4.42"
      ></path>
      <path
        className={classes.path}
        d="M84.8 57.42c-2.84-1.27-5.16 2.4-2.14 3.84 3.09 1.72 5.61-2.52 2.14-3.84"
      ></path>
      <path
        className={classes.path}
        d="M95.06 60.87c-2.06-1.55-6.46-2.28-6.16 1.48.47 2 2.92 2.43 4.52 3.25 2.86.73 4.24-3.25 1.64-4.73"
      ></path>
      <path
        className={classes.path}
        d="M106 68.87c-1.71-2.11-5.78-6-7.75-2.22-.69 1.66.73 2.93 1.92 3.86s2.13 2.64 3.84 2.77a2.66 2.66 0 0 0 2-4.41"
      ></path>
      <path
        className={classes.path}
        d="M40.1 70.32a20.09 20.09 0 0 0 2.22 3.74 3.6 3.6 0 0 0 2.83 1.47 3 3 0 0 0 3.08-3.18 3.77 3.77 0 0 0-.49-1.75c-.15-.29-.43-.87-.58-1.17s-.09-.39.08-.48a.35.35 0 0 1 .48.16l.65 1a3.33 3.33 0 0 0 5.77-3.29c-.08-.18-.25-.54-.34-.72a.32.32 0 1 1 .54-.34l.52.78a3.06 3.06 0 0 0 5.52-1.92 5.32 5.32 0 0 0-.53-2 23.5 23.5 0 0 1-2-10.43c0-10.63 8.3-19.24 18.34-19.24a19.71 19.71 0 0 1 19.2 13.28.69.69 0 0 0 .87.46L116 40.7a.69.69 0 0 0 .46-.87A41.42 41.42 0 0 0 76.41 11c-18.06 0-33.07 12.08-38.57 28.41a41 41 0 0 0 2.26 30.91"
      ></path>
      <path
        className={classes.path}
        d="M76.82 89.53a1.92 1.92 0 1 1-1.92 1.92 1.92 1.92 0 0 1 1.92-1.92"
      ></path>
      <path
        className={classes.path}
        d="M76.82 81.31a1.92 1.92 0 1 1-1.92 1.92 1.91 1.91 0 0 1 1.92-1.92"
      ></path>
      <path
        className={classes.path}
        d="M61.69 72a18.66 18.66 0 0 0 7 4 2.33 2.33 0 1 0 1.58-4.34 32 32 0 0 1-5.36-3.19A2.37 2.37 0 0 0 61.69 72"
      ></path>
      <path
        className={classes.path}
        d="M54.83 76.83a13.7 13.7 0 0 0 5.59 3.74 2.39 2.39 0 0 0 2.65-3.51c-1.12-1.57-2.9-2.29-4.27-3.63a3.8 3.8 0 0 0-1.94-1.17 2.63 2.63 0 0 0-2 4.57"
      ></path>
      <path
        className={classes.path}
        d="M66.85 83.83a7.06 7.06 0 0 0 3.87.76 2.18 2.18 0 0 0 1.3-3.3c-.93-1.13-2.78-1.36-4.13-1.88a2.28 2.28 0 0 0-1 4.42"
      ></path>
      <path
        className={classes.path}
        d="M68.85 92.58c2.83 1.27 5.16-2.4 2.14-3.84-3.09-1.72-5.62 2.52-2.14 3.84"
      ></path>
      <path
        className={classes.path}
        d="M58.58 89.13c2.07 1.55 6.47 2.28 6.16-1.48-.46-2-2.92-2.43-4.51-3.25-2.86-.73-4.25 3.25-1.65 4.73"
      ></path>
      <path
        className={classes.path}
        d="M47.68 81.13c1.72 2.11 5.78 6 7.76 2.22.68-1.66-.74-2.93-1.92-3.86s-2.14-2.64-3.84-2.77a2.66 2.66 0 0 0-2 4.41"
      ></path>
    </svg>
  );
};

export default LogoIcon;
